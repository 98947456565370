/* eslint-disable @typescript-eslint/no-unused-vars */

import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { PLATFORMS_CONSTS } from "../../config";

export default function AppIndex() {
  const router = useRouter();
  const { data: session } = useSession();
  useEffect(() => {
    if (session) {
      router.push(PLATFORMS_CONSTS.SYNC_SITE_GESTOR_ENDPOINT as string);
    }
  }, [session]);
  return <></>;
}
